import React, {Component} from 'react';
import './canvas.css';
import {Button} from '@material-ui/core';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }),
);

export class Canvas extends Component<any, any> {
    render() {

        return (
            <div className="canvas">
                <img id="logo" src='/images/logo.png'/>
                <Button className="button" href="http://resume.qbix.pl" variant='contained' color='primary'>My
                    Resume</Button>
                <Button className="button" href="http://blog.qbix.pl" variant='contained' color='primary'>My
                    Blog</Button>
            </div>
        );
    }

    goToResume = () => {
        window.open('http://resume.qbix.pl', '_blank');
    };
}
